import _ from 'lodash';
import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Chart } from '../../helpers';
import { months, months_map } from '../utils';
import { createChartColors, OrderTypesColor } from '../utils/chart_utils';
import { colors } from '@commonsku/styles';
import { useHasCapabilities } from '../../../hooks';

const today = new Date();
const ProjectionChart = React.memo((props) => {
  const {
    salesTargets = [],
    orders = [],
    onClickColumn,
    height="350px",
  } = props;

  const projectionChartData = React.useMemo(() => {
    const formatText = (text) => (text || '').split(' ').join('_').toLowerCase();
    const sales_targets = _.groupBy(
      salesTargets,
      v => v.month_name + ' ' + v.year,
    );
    const ordersGrouped = _.groupBy(
      orders,
      v => v.month_name + ' ' + v.year,
    );
    const types = ['PRESALES', 'SALES ORDER', 'INVOICE'];

    return [0, 1, 2].map((i) => {
      const dt = new Date(today.getFullYear(), today.getMonth(), 1);
      dt.setMonth(dt.getMonth() + i);

      const group_by = months_map[months[dt.getMonth()]] + ' ' + dt.getFullYear();
      const st = _.get(sales_targets, group_by, []);
      const o = _.get(ordersGrouped, group_by, []);
      if (o.length === 0) {
        return {
          group_by: group_by,
          year: dt.getFullYear(),
          month: dt.getMonth() + 1, // idx + 1 = actual month num
          month_name: months_map[months[dt.getMonth()]],
          presales: 0,
          sales_order: 0,
          invoice: 0,
          ...(st.length > 0 ? { target: parseFloat(st[0].target) } : { target: 0 }),
        };
      }

      const typesLeft = types.filter(t => o.filter(or => or.order_type === t).length === 0);
      return {
        group_by: group_by,
        year: o[0].year,
        month: o[0].month,
        month_name: o[0].month_name,
        [formatText(o[0].order_type)]: parseFloat(o[0].total),
        ...(o.length > 1 ? { [formatText(o[1].order_type)]: parseFloat(o[1].total) } : (
          typesLeft.length > 1 ? { [formatText(typesLeft[1])]: 0 } : {}
        )),
        ...(o.length > 2 ? { [formatText(o[2].order_type)]: parseFloat(o[2].total) } : (
          typesLeft.length > 0 ? { [formatText(typesLeft[0])]: 0 } : {}
        )),
        ...(st.length > 0 ? { target: parseFloat(st[0].target) } : { target: 0 }),
      };
    });
  }, [salesTargets, orders]);

  const hasSalesTargets = useHasCapabilities(['HAS-SALES-TARGETS']);

  return (
    <Chart
      type="bar"
      width="100%"
      data={projectionChartData}
      onChartReady={projectionChart({ onClickColumn, isLegendCheckbox: true, hasSalesTargets })}
      height={height}
    />
  );
});

export function projectionChart({ onClickColumn = null, isLegendCheckbox = false, hasSalesTargets = true }) {
  return function (chart, data) {
    chart.colors.list = createChartColors([
      OrderTypesColor['PRE-SALES'].main,
      OrderTypesColor['SALES ORDER'].main,
      OrderTypesColor['INVOICE'].main,
      OrderTypesColor['TARGET'].main,
    ]);

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "month_name";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.grid.template.disabled = true;
    const label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 200;
    label.tooltipText = "{category}";

    categoryAxis.events.on("sizechanged", function (ev) {
      let axis = ev.target;
      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < 150) {
        axis.renderer.labels.template.fontSize = 13;
      } else {
        axis.renderer.labels.template.fontSize = 15;
      }
    });

    categoryAxis.renderer.labels.template.events.on("hit", function (ev) {
      onClickColumn && onClickColumn(
        ev.target.dataItem.dataContext,
        _.get(ev.target.dataItem, ['component', 'name'], null),
      );
    }, this);

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.extraMax = 0.1;
    valueAxis.renderer.minGridDistance = 100;

    categoryAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.grid.template.disabled = true;

    categoryAxis.cursorTooltipEnabled = false;
    valueAxis.cursorTooltipEnabled = false;

    valueAxis.renderer.labels.template.fill = colors.neutrals['100'];
    categoryAxis.renderer.labels.template.fill = colors.neutrals['100'];

    [{
      name: 'Pre-sales',
      valueY: 'presales',
      categoryX: 'month_name',
      color: OrderTypesColor['PRE-SALES'].main,
    }, {
      name: 'Sales Orders',
      valueY: 'sales_order',
      categoryX: 'month_name',
      color: OrderTypesColor['SALES ORDER'].main,
    }, {
      name: 'Invoices',
      valueY: 'invoice',
      categoryX: 'month_name',
      color: OrderTypesColor['INVOICE'].main,
    }].forEach(s => {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.name = s.name;
      series.dataFields.valueY = s.valueY;
      series.dataFields.categoryX = s.categoryX;
      series.columns.template.strokeOpacity = 0;

      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color(s.color);
      series.tooltipText = "${valueY.formatNumber('#,###.00')}";
      series.maxTooltipDistance = 20;

      series.tooltip.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      series.tooltip.keepTargetHover = true;
      series.tooltip.events.on("hit", function (ev) {
        onClickColumn && onClickColumn(
          ev.target.dataItem.dataContext,
          _.get(ev.target.dataItem, ['component', 'name'], null),
        );
      }, this);

      series.columns.template.events.on("hit", function (ev) {
        onClickColumn && onClickColumn(
          ev.target.dataItem.dataContext,
          _.get(ev.target.dataItem, ['component', 'name'], null),
        );
      }, this);

      series.stacked = true;
    });

    if (hasSalesTargets) {
      const series2 = chart.series.push(new am4charts.LineSeries());
      series2.name = "INV Target";
      series2.stroke = am4core.color(OrderTypesColor['TARGET'].main);
      series2.strokeWidth = 2;
      series2.dataFields.valueY = "target";
      series2.dataFields.categoryX = "month_name";
      series2.bullets.push(new am4charts.CircleBullet());
      series2.tooltip.getFillFromObject = false;
      series2.tooltip.background.fill = am4core.color(OrderTypesColor['TARGET'].main);
      series2.tooltipText = "${valueY}";
    }

    chart.legend = new am4charts.Legend();

    if (isLegendCheckbox) {
      chart.legend.useDefaultMarker = true;
      const marker = chart.legend.markers.template;
      const markerColumn = marker.children.getIndex(0);

      markerColumn.states.getKey("active");
      markerColumn.cornerRadius(3, 3, 3, 3);
      markerColumn.defaultState.properties.strokeWidth = 0;

      // custom html checkmark
      const checkbox = marker.createChild(am4core.Label);
      checkbox.html = "<span style='color:white ;padding-left:5px'> &#10004; <span>";

      const checkboxActiveState = checkbox.states.create("active");
      checkboxActiveState.properties.opacity = 0;
    }

    const cursor = new am4charts.XYCursor();
    chart.cursor = cursor;
    cursor.lineX.disabled = true;
    cursor.lineY.disabled = true;
    cursor.behavior = "none";

    chart.numberFormatter.numberFormat = "#a";
  };
}

export default ProjectionChart;
